<mat-toolbar color="primary" class="expanded-toolbar">
  <mat-toolbar color="primary" class="expanded-toolbar">
    <span>
      <button mat-button routerLink="/">{{title}}</button>
      <button mat-button routerLink="/">
        <mat-icon>home</mat-icon>
      </button>
    </span>
  </mat-toolbar>
    <span>
      <button mat-button routerLink="/renders/" *ngIf="isAuthenticated"><mat-icon>computer</mat-icon>Renderers</button>
    </span>
    <span>
      <button mat-button routerLink="/rendering/" *ngIf="isAuthenticated"><mat-icon>image</mat-icon>Rendering</button>
    </span>
    <span>
      <button mat-button routerLink="/stats/" *ngIf="isAuthenticated"><mat-icon>insert_chart_outlined</mat-icon>Stats</button>
    </span>
     
<span>
  <button mat-menu-item *ngIf="isAuthenticated" (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    Logout
  </button>
</span>
  <button mat-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
    <mat-icon>menu</mat-icon>
  </button>
</mat-toolbar>

<mat-menu x-position="before" #menu="matMenu">
  <button mat-menu-item routerLink="/">
    <mat-icon>home</mat-icon>
    Home
  </button>
  <button mat-menu-item routerLink="/renders/" *ngIf="isAuthenticated">
    <mat-icon>computer</mat-icon>
    Renderers
  </button>
  <button mat-menu-item routerLink="/rendering/" *ngIf="isAuthenticated">
    <mat-icon>image</mat-icon>
    Rendering
  </button>
  <button mat-menu-item routerLink="/stats/" *ngIf="isAuthenticated">
    <mat-icon>insert_chart_outlined</mat-icon>
    Stats
  </button>
  <button mat-menu-item routerLink="/auth//notifmanager/" *ngIf="isAuthenticated">
    <mat-icon>sms</mat-icon>
    Users Notifs
  </button>
  <button mat-menu-item routerLink="/auth/login" *ngIf="!isAuthenticated">
    <mat-icon>power_settings_new</mat-icon>
    Login
  </button>
  <button mat-menu-item *ngIf="isAuthenticated" (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    Logout
  </button>
</mat-menu>
<router-outlet></router-outlet>