import { Component ,OnInit } from '@angular/core';
import { AuthService } from './auth/services/auth.service';
import { Router , ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'taskmanagerapp';
  isAuthenticated = false;
  
  // tslint:disable-next-line: typedef
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.isAuthenticated = this.authService.isLoggedIn()
    console.log ()
  }
  
  logout() {
        this.isAuthenticated = false ;
        this.authService.logout()
        this.router.navigate(['/auth/login']);
        console.log('logout');
 }

}
