import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard', pathMatch: 'full' 
    
    // canLoad: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'renders',
    loadChildren: () => import('./renders/renders.module').then(m => m.RendersModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'rendering',
    loadChildren: () => import('./tasks/tasks.module').then(m => m.TasksModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'stats',
    loadChildren: () => import('./stats/stats.module').then(m => m.StatsModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'notifmanager',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    // canLoad: [AuthGuard]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
