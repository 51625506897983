
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap, mapTo } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from '../../services/http-error-handler.service';
import { environment } from '../../../environments/environment';
import { User } from '../models/user';
import { Router } from '@angular/router';

// import * as JWT from 'jwt-decode';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiUrl = `${environment.apiUrl}`;
  private apiUrlAuth = this.apiUrl + '/auth'
  private handleError: HandleError;
  
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  redirectUrl: string | undefined;

  constructor(
    private http: HttpClient,
    private router: Router,
    

    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = this.httpErrorHandler.createHandleError('AuthService');
  }
  // tslint:disable-next-line: typedef
  signup(data: User) {
    return this.http.post(`${this.apiUrlAuth}/signup`, data, this.httpOptions)
    .pipe(
      catchError(this.handleError('signup', null))
    );
  }
   // tslint:disable-next-line: typedef
  resetPassword(data: User) {
    return this.http.patch(`${this.apiUrl}/users/resetpassword`, data, this.httpOptions)
    .pipe(
      catchError(this.handleError('resetPassword', null))
    );
  }
  // tslint:disable-next-line: typedef
  activeUser(data: User) {
    return this.http.patch(`${this.apiUrlAuth}/activeUser`, data, this.httpOptions)
    .pipe(
      catchError(this.handleError('activeUser', null))
    );
  }
  setNotifUser(data: User) {
    return this.http.patch(`${this.apiUrl}/users/setnotifuser`, data, this.httpOptions)
    .pipe(
      catchError(this.handleError('setNotifUser', null))
    );
  }

  login(data: any): Observable<boolean> {
    return this.http.post(`${this.apiUrlAuth}/login`, data, this.httpOptions)
    .pipe(
      tap(user => this.doLogin(user)),
      mapTo(true),
      catchError(this.handleError('login', false))
    );
  }
   // tslint:disable-next-line: typedef
  doLogin(user: any) {
    
    localStorage.setItem('currentUser', JSON.stringify(user));
  }
   // tslint:disable-next-line: typedef
  getCurrentUser() {
    const  currentUser = localStorage.getItem('currentUser');
    // tslint:disable-next-line: null
    return currentUser && currentUser != 'null' && currentUser != null ? JSON.parse(currentUser) : '';
  }

  getDecodeToken(token: string): any {
    const t = jwt_decode.default(token);
    return t;
  }
  // tslint:disable-next-line: typedef
  refreshToken(): Observable<boolean> {
    const  currentUser = this.getCurrentUser()
    return this.http.post(`${this.apiUrlAuth}/refreshtoken`, {tokenRefresh : currentUser.tokenRefresh  }, this.httpOptions)
    .pipe(
      tap(user => this.doLogin(user)),
      mapTo(true),
      catchError(this.handleError('refreshToken', false))
    );
  }
  
  hasRefreshToken() {
    const currentUser = this.getCurrentUser();
    if (currentUser) {
      const token = this.getDecodeToken(currentUser.tokenRefresh);
      const currentTime = Math.round((new Date()).getTime() / 1000);
      if (token.exp > currentTime) {
        return true;
      } else {
        return false;

      }
    }
    return false;
  }
  // tslint:disable-next-line: typedef
  isLoggedIn(): boolean {
    const currentUser = this.getCurrentUser();
    if (currentUser !== 'null' && currentUser !== null && currentUser !== '') {
      const token = this.getDecodeToken(currentUser.token);
      const currentTime = Math.round((new Date()).getTime() / 1000);
      if (token.exp > currentTime) {
        return true;
      } else {
        return false;

      }
    }
    return false;
  }
  // tslint:disable-next-line: typedef
  logout() {
    localStorage.removeItem('currentUser');
    this.router.navigate(['/auth/login']);
  }

}
