import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { HttpErrorHandler } from './services/http-error-handler.service';
import { httpInterceptorProviders } from './http-interceptors/';
import { MessageService } from './services/message.service';
import { LOCALE_ID } from '@angular/core';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
//import { SharedModule } from './shared/shared.module';
import {ToastrModule } from 'ngx-toastr';

const modules: any[] = [
  // BrowserModule,
  AppRoutingModule,
  BrowserAnimationsModule,
  AngularMaterialModule,
  HttpClientModule,
  //SharedModule,
 // FlexLayoutModule
 // SharedModule
  //AuthModule,
  
  
]
registerLocaleData(localeFr);
@NgModule({
  declarations: [
    AppComponent,
    
  ],
  imports: [...modules,ToastrModule.forRoot()//,SharedModule
   ],
  exports: [...modules],
  providers: [
    HttpErrorHandler,
    HttpClientModule,
    MessageService,
    
    httpInterceptorProviders,
    { provide: LOCALE_ID, useValue: "fr-FR" }
  
  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule { }
